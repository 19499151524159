*[class$='jss'],
*[class$='jss']:hover {
  color: unset;
  padding: unset;
  margin: unset;
  background-color: unset;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-by {
  min-width: 120px !important;
  font-size: 14px !important;
  text-align: center !important;
}

.update-by span {
  font-size: 12px !important;
}

.status {
  width: 160px;
}

/* .Toastify__toast-container{ */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 5px solid var(--toastify-color-success) !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 10px solid var(--toastify-color-error) !important;
}

.container-card .card {
  border: 1px solid #e5e5e5 !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border: 1px solid #e5e5e5 !important;
}

/* Utility */
h5.required::after,
label.required::after {
  content: '*';
  color: red;
}

label.Mui-required .MuiInputLabel-asterisk {
  color: red;
}

.select-search__input {
  border: 1px solid #d3d3d4 !important;
}

/* typo */
.text-left {
  text-align: left;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* dropdown */
.dropdown .dropdown-item:not(.disabled):active {
  background-color: transparent;
  color: #212529;
}

/* modal */
.modal-backdrop.show {
  background-color: rgba(35, 31, 32, 1);
  opacity: 0.3;
  z-index: 2010;
}

.modal.show {
  z-index: 2015;
}

.modal-fullscreen,
.modal-fullscreen-sm-down,
.modal-fullscreen-md-down,
.modal-fullscreen-lg-down,
.modal-fullscreen-xxl-down {
  padding: 1rem !important;
}

/* .text-nowrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */

/* custom scroll */
.custom-scroll {
  scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.MuiDivider-fullWidth {
  border-color: rgb(94, 94, 94) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

/* TOQASTIFY */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  width: auto;
  z-index: 9999;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  width: auto;
  z-index: 9999;
}

/* radio */
.custom-radio>span:first-of-type {
  position: relative;
}

.custom-radio>span:first-of-type svg {
  display: none;
}

.custom-radio>span:first-of-type:before,
.custom-radio>span:first-of-type:after {
  content: '';
  display: block;
  border-radius: 100px;
}

.custom-radio>span:first-of-type:before {
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid #e5e5e5;
}

.custom-radio>span:first-of-type:after {
  width: 15px;
  height: 15px;
  background-color: #1cb99a;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.custom-radio.Mui-checked>span:first-of-type:after {
  transform: translate(-50%, -50%) scale(1);
}

.ant-picker-dropdown {
  z-index: 2020;
}

.dropdown-item:hover {
  background-color: rgba(28, 185, 154, 0.06);
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

/* Chrome, Safari, Edge, Opera */
.no-up-down input::-webkit-outer-spin-button,
.no-up-down input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].no-up-down {
  -moz-appearance: textfield;
}

.btn-table-action:hover,
.btn-table-action:active,
.btn-table-action:focus {
  box-shadow: none !important;
}

.dropdown-table-action {
  border-color: #e5e5e5;
  border-radius: 3px;
}

/* Tooltip */
.MuiTooltip-popper {
  z-index: 9999999 !important;
}

/* Loading */
.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top-color: rgba(56, 80, 128, 0.8);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  animation: spinner 1s linear infinite;
}

.loading-spinner-small {
  border-width: 2px;
  width: 1.5em;
  height: 1.5em;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* loading (ring) */
/* svg.loading-test {
  width: 3.75em;
  animation: 1.5s spin ease infinite;
}
.loading-test>.ring {
  fill: none;
  stroke: rgba(85, 104, 142, 0.8);
  stroke-width: 2;
}
.loading-test .ball {
  fill: #2F4269;
  stroke: rgba(255, 255, 255, 0.55);
  stroke-width: 1px;
  transform: scale(1.4);
}
@keyframes spin {
  to {
    transform: rotate(335deg);
  }
} */

@media screen and (min-width: 1200px) {

  /* custom scroll */
  .custom-scroll::-webkit-scrollbar {
    height: 7px;
  }
}