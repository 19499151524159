@import url(https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp);
.form-switch .form-check-input {
    height: 25px !important;
    width: 48px !important;
}

.form-switch .form-check-input {
    cursor: pointer;
    background-color: #9e9e9e !important;
    border-color: #9e9e9e !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:checked {
    background-color: #28a745 !important;
    border-color: #28a745 !important;
}

.form-switch .form-check-input:focus {
    box-shadow: none !important;
}

.form-switch .form-check-input:focus:not(:checked) {
    border-color: #9e9e9e !important;
}
.MuiOutlinedInput-notchedOutline {
    /* margin-top: 3px !important; */
    top: 0 !important;
  }
  
  /**
   * Main wrapper
   */
  .select-search {
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
  }
  
  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }
  
  /**
    * Value wrapper
    */
  .select-search__value {
    /* position: relative; */
    z-index: 1;
  }
  
  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }
  
  /**
    * Input
    */
  .select-search__input {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: 1px solid transparent;
    /* box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15); */
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }
  
  .MuiSelect-select.MuiSelect-outlined {
    border: unset;
  }
  
  .MuiSelect-root .MuiSelect-notchedOutline {
    border-color: #0000001f !important;
  }
  
  /* .MuiSelect-root.Mui-focused .MuiSelect-notchedOutline {
    border-color: #1cb99a !important;
  } */
  
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #ffffff !important;
  }
  
  .MuiSelect-input {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .MuiSelect-root.Mui-focused .MuiSelect-input {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .MuiInputLabel-outlined {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  
  .MuiInputLabel-outlined.Mui-focused {
    color: #1cb99a !important;
  }
  
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: #1cb99a !important; */
    border-width: 1px !important;
  }
  
  .css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #fff;
  }
  
  .MuiMenuItem-root {
    font-size: 14px !important;
  }
  
  .ant-select-dropdown {
    z-index: 100000 !important;
  }
  
  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
  
  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }
  
  /**
    * Options wrapper
    */
  .select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  }
  
  /**
    * Options
    */
  .select-search__options {
    list-style: none;
    padding: 0;
  }
  
  /**
    * Option row
    */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }
  
  /**
    * Option
    */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }
  
  .select-search--multiple .select-search__option {
    height: 48px;
  }
  
  .select-search__option.is-selected {
    background: #2fcc8b;
    color: #fff;
  }
  
  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(47, 204, 139, 0.1);
  }
  
  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: #2eb378;
    color: #fff;
  }
  
  /**
    * Group
    */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }
  
  /**
    * States
    */
  .select-search.is-disabled {
    opacity: 0.5;
  }
  
  .select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
  }
  
  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }
  
  /**
    * Modifiers
    */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }
  
  .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }
  
  .select-search--multiple .select-search__input {
    cursor: initial;
  }
  
  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }
  
  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }
  
  .select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2fcc8b;
  }
  
  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }
  
  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }
  
  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
  
  /* style-filter-select-search */
  .style-filter-select-search-wrap.indexBelowNavbar {
    z-index: 1999 !important;
  }
  
  .style-filter-select-search {
    margin-top: 4px;
    border-radius: 4px !important;
    mix-blend-mode: multiply;
    background-blend-mode: multiply;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2) !important;
    background: linear-gradient(to bottom, #fff, #fff);
    height: auto;
  }
  
  .style-filter-select-search .MuiList-root {
    /* max-height: 300px; */
  }
  
  .style-filter-select-search .MuiAutocomplete-option {
    padding: 0.5rem 1.5rem !important;
    color: #29302E;
  }
  
  .style-filter-select-search .MuiAutocomplete-option[aria-selected='true'],
  .style-filter-select-search .MuiMenuItem-root.Mui-selected {
    background-color: transparent !important;
  }
  
  .Mui-multiple .MuiAutocomplete-option[aria-selected='true'],
  .style-filter-select-search .MuiAutocomplete-option.Mui-focused:hover,
  .style-filter-select-search .MuiAutocomplete-option.Mui-focused,
  .style-filter-select-search .MuiAutocomplete-option[aria-selected='true'].Mui-focused,
  .style-filter-select-search .MuiMenuItem-root:hover {
    background: rgba(28, 185, 154, 0.06) !important;
    color: #1cb99a;
  }
  
  
  .Mui-multiple .MuiAutocomplete-option.Mui-focused {
    background-color: transparent !important;
    color: #29302E;
  }
  
  /* basic select */
  .style-basic-select {
    max-height: 300px !important;
  }
  
  .style-filter-select-search .MuiMenuItem-root {
    font-size: 16px !important;
    display: flex !important;
    justify-content: flex-start !important;
    padding: 6px 16px !important;
    background-color: #fff !important;
  }

/* level 1 */
.container-menu .menu-first {
  /* position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2000; */
  z-index: 2000;
  background: linear-gradient(177deg, rgba(255, 255, 255, 1) 0%, rgba(242, 242, 242, 1) 100%);
  transition: max-width 0.3s ease;
  border: 1px solid #e5e5e5;
  /* border-radius: 0 0.5rem 0.5rem 0; */
  padding: 16px 0;
  height: 100vh;
}

.container-menu .menu-first.show {
  z-index: 2000;
  border-top-right-radius: 0;
}

.container-menu .menu-first.show:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(171deg, rgba(255, 255, 255, 1) 1%, rgba(242, 242, 242, 1) 99%);
  backdrop-filter: saturate(1) blur(20px);
  -webkit-backdrop-filter: saturate(1) blur(20px);
  z-index: -1;
}

.menu-first .menu-first-level .flex-name {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.375rem 1.5rem;
  margin: 0.25rem 0;
  cursor: pointer;
}

.menu-first .menu-first-level .flex-name.menu-first-arrow::after {
  content: 'arrow_right';
  font-family: 'Material Icons Round';
  margin-left: auto;
  margin-right: -8px;
  font-size: 22px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.menu-first.show .menu-first-level .flex-name.menu-first-arrow:hover::after,
.menu-first.show .menu-first-level .flex-name.menu-first-arrow.active::after {
  opacity: 1;
}

.menu-first .menu-first-level .flex-name.active .menu-first-text {
  font-weight: 600;
}

.menu-first .menu-first-level .menu-first-text {
  padding-left: 16px;
  display: none;
}

.menu-first .menu-first-level .menu-first-icon.active {
  position: relative;
  z-index: 0;
}

.menu-first .menu-first-level .menu-first-icon {
  background-color: transparent !important;
}

.menu-first .menu-first-level .menu-first-icon.active::before {
  content: '';
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: -8px;
  right: -8px;
  border-radius: 100px;
  z-index: -1;
  transition: background-color 0.3s ease;
}

.menu-first.show .menu-first-level .flex-name .menu-first-icon::before,
.menu-first .menu-first-level .flex-name:hover .menu-first-icon.active::before,
.menu-first .menu-first-level .flex-name.active .menu-first-icon.active::before {
  background-color: transparent;
}

.menu-first.show .menu-first-level .menu-first-text {
  display: block;
  color: #231f20;
}

/* level 2 */
.menu-second {
  display: none;
}

.menu-second.active {
  display: block;
  position: absolute;
  top: -1px;
  /* bottom: 0; */
  left: 100%;
}

.menu-second .menu-second-wrap {
  padding: 1rem 0 1.25rem;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(242, 242, 242, 1));
  backdrop-filter: saturate(1) blur(35px);
  -webkit-backdrop-filter: saturate(1) blur(35px);
  border: 1px solid #e5e5e5;
  border-radius: 0 0.5rem 0.5rem 0;
}

.menu-second .menu-second-level {
  position: relative;
}

.menu-second .menu-second-level .item-second-list {
  position: unset;
  margin: 0.25rem 0;
  padding: 0.5785rem 1rem;
  justify-content: space-between;
  text-align: left;
  font-size: 16px;
  font-weight: 400 !important;
  cursor: pointer;
  color: #231f20;
}

.menu-second .menu-second-level .item-second-list svg {
  font-size: 24px;
}

.menu-second .menu-second-level .item-second-list[aria-expanded='true'] span {
  font-weight: 600;
}

/* level3 as Collapse */
.menu-third .menu-third-level {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu-third .menu-third-level .item-third-list {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  margin: 0.25rem 0;
}

.menu-third .menu-third-level .item-third-list::before {
  content: 'fiber_manual_record';
  font-family: 'Material Icons' !important;
  font-size: 11px;
  margin-right: 0.5rem;
}

.menu-first .menu-first-wrap {
  overflow-x: hidden;
}

.menu-first .menu-first-wrap,
.menu-second .menu-second-wrap {
  overflow-y: auto;
  scrollbar-width: thin;
}

.menu-first .menu-first-wrap::-webkit-scrollbar,
.menu-second .menu-second-wrap::-webkit-scrollbar {
  width: 0.25rem;
}

.menu-first .menu-first-wrap::-webkit-scrollbar-thumb,
.menu-second .menu-second-wrap::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 50px;
}

/* backdrop */
.sidebar-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #231f20;
  opacity: 0.3;
  z-index: 1999;
}

/* tooltip */
.customTooltip-sidebar[data-title]:before {
  content: attr(data-title);
  color: transparent !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: transparent;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.customTooltip-sidebar[data-title]:after {
  content: attr(data-title);
  color: #fff !important;
  position: absolute;
  top: 100%;
  /* left: 0; */
  right: 0;
  /* width: max-content; */
  font-weight: 300;
  font-size: 14px;
  display: none;
  background-color: #444;
  padding: 3px 6px;
  border-radius: 4px;
  line-height: normal;
}

.menu-second .menu-second-level button.item-second-list .customTooltip-sidebar[data-title]:after {
  top: 51px;
}

@media screen and (max-width: 1359px) {
  .container-menu .menu-first {
    position: fixed;
    top: 72px;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 899px) {
  .customTooltip-sidebar[data-title]:after {
    display: none !important;
  }

  .container-menu .menu-first.show:before,
  .menu-second .menu-second-wrap {
    background: #f5f5f5;
  }
}

@media screen and (min-width: 900px) {

  /* show tooltip */
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:before,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:before,
  .menu-first .menu-first-level .flex-name:hover .customTooltip-sidebar[data-title]:after,
  .menu-second .menu-second-level button.item-second-list:hover .customTooltip-sidebar[data-title]:after,
  .menu-third .menu-third-level .item-third-list:hover .customTooltip-sidebar[data-title]:after {
    display: block;
  }
}
@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(/static/media/Kanit-Light.a6547ac5.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(/static/media/Kanit-Regular.a9ef7708.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(/static/media/Kanit-Medium.c5ab4123.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(/static/media/Kanit-SemiBold.4391385c.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Sarabun';
  src: local('Sarabun'), url(/static/media/Sarabun-Regular.e4a771fc.ttf) format('truetype');
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Kanit', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Kanit', sans-serif !important;
  letter-spacing: 0.5px;
}

code {
  font-family: 'Kanit', monospace;
}

.swal2-container {
  z-index: 20000 !important;
}

.print-pdf-sarabun p {
  font-family: 'Sarabun' !important;
  letter-spacing: 0.5px;
}

/* *[class*="jss"],
*[class*="jss"]:hover {
  color: unset !important;
  background-color: unset !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.btn-delete{
    background-color: #ff0000;
    color: #ffffff;
    cursor: 'pointer';
}
a.link-nav{
    color: #000000;
    text-decoration: unset ;
}
a.link-nav{
    color: #000000;
    text-decoration: none ;
}
/* a.link-nav:hover{
    color: #1cb99a;
    text-decoration: underline ;
}
.MuiFormLabel-root.Mui-focused{
    color: #1cb99a !important;
} */
.MuiFormControl-root{
    min-width: 200px ;
}
 
.print-show {
    display: none;
  }
  
  body > .print-show {
    display: block;
  }
  
  @media print {
    .print-show {
      display: block;
    }
  }

  .print-show-disable {
    display: block;
  }
  
  body > .print-show-disable {
    display: block;
  }
  
  @media print {
    .print-show-disable {
      display: none;
    }
  }
*[class$='jss'],
*[class$='jss']:hover {
  color: unset;
  padding: unset;
  margin: unset;
  background-color: unset;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-by {
  min-width: 120px !important;
  font-size: 14px !important;
  text-align: center !important;
}

.update-by span {
  font-size: 12px !important;
}

.status {
  width: 160px;
}

/* .Toastify__toast-container{ */
.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--success {
  border-left: 5px solid var(--toastify-color-success) !important;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  border-left: 10px solid var(--toastify-color-error) !important;
}

.container-card .card {
  border: 1px solid #e5e5e5 !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #0000001a !important;
  border: 1px solid #e5e5e5 !important;
}

/* Utility */
h5.required::after,
label.required::after {
  content: '*';
  color: red;
}

label.Mui-required .MuiInputLabel-asterisk {
  color: red;
}

.select-search__input {
  border: 1px solid #d3d3d4 !important;
}

/* typo */
.text-left {
  text-align: left;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* dropdown */
.dropdown .dropdown-item:not(.disabled):active {
  background-color: transparent;
  color: #212529;
}

/* modal */
.modal-backdrop.show {
  background-color: rgba(35, 31, 32, 1);
  opacity: 0.3;
  z-index: 2010;
}

.modal.show {
  z-index: 2015;
}

.modal-fullscreen,
.modal-fullscreen-sm-down,
.modal-fullscreen-md-down,
.modal-fullscreen-lg-down,
.modal-fullscreen-xxl-down {
  padding: 1rem !important;
}

/* .text-nowrap {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
} */

/* custom scroll */
.custom-scroll {
  scrollbar-width: thin;
}

.custom-scroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.MuiDivider-fullWidth {
  border-color: rgb(94, 94, 94) !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

/* TOQASTIFY */
.Toastify__toast-container {
  z-index: 9999;
}

.Toastify__toast-container.Toastify__toast-container--bottom-right {
  width: auto;
  z-index: 9999;
}

.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error {
  width: auto;
  z-index: 9999;
}

/* radio */
.custom-radio>span:first-of-type {
  position: relative;
}

.custom-radio>span:first-of-type svg {
  display: none;
}

.custom-radio>span:first-of-type:before,
.custom-radio>span:first-of-type:after {
  content: '';
  display: block;
  border-radius: 100px;
}

.custom-radio>span:first-of-type:before {
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1px solid #e5e5e5;
}

.custom-radio>span:first-of-type:after {
  width: 15px;
  height: 15px;
  background-color: #1cb99a;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0);
          transform: translate(-50%, -50%) scale(0);
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}

.custom-radio.Mui-checked>span:first-of-type:after {
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
}

.ant-picker-dropdown {
  z-index: 2020;
}

.dropdown-item:hover {
  background-color: rgba(28, 185, 154, 0.06);
}

.btn-check:focus+.btn,
.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}

input[type='time']::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
          filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}

/* Chrome, Safari, Edge, Opera */
.no-up-down input::-webkit-outer-spin-button,
.no-up-down input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].no-up-down {
  -moz-appearance: textfield;
}

.btn-table-action:hover,
.btn-table-action:active,
.btn-table-action:focus {
  box-shadow: none !important;
}

.dropdown-table-action {
  border-color: #e5e5e5;
  border-radius: 3px;
}

/* Tooltip */
.MuiTooltip-popper {
  z-index: 9999999 !important;
}

/* Loading */
.loading-spinner {
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top-color: rgba(56, 80, 128, 0.8);
  border-radius: 50%;
  width: 3em;
  height: 3em;
  -webkit-animation: spinner 1s linear infinite;
          animation: spinner 1s linear infinite;
}

.loading-spinner-small {
  border-width: 2px;
  width: 1.5em;
  height: 1.5em;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* loading (ring) */
/* svg.loading-test {
  width: 3.75em;
  animation: 1.5s spin ease infinite;
}
.loading-test>.ring {
  fill: none;
  stroke: rgba(85, 104, 142, 0.8);
  stroke-width: 2;
}
.loading-test .ball {
  fill: #2F4269;
  stroke: rgba(255, 255, 255, 0.55);
  stroke-width: 1px;
  transform: scale(1.4);
}
@keyframes spin {
  to {
    transform: rotate(335deg);
  }
} */

@media screen and (min-width: 1200px) {

  /* custom scroll */
  .custom-scroll::-webkit-scrollbar {
    height: 7px;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: 'Sarabun', monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-italic {
  font-style: italic !important;
}

.sm-data-table .MuiTableCell-root {
  padding: 2px 15px;
}
.custom-date-picker .ant-picker-panel-container {
  border-radius: 4px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin: 4px 0;
}

.custom-date-picker .ant-picker-header-super-prev-btn,
.custom-date-picker .ant-picker-header-super-next-btn,
.custom-date-picker .ant-picker-header-prev-btn,
.custom-date-picker .ant-picker-header-next-btn {
  color: #1cb99a;
}

.custom-date-picker button:hover {
  color: #1cb99a;
}

.custom-date-picker .ant-picker-today-btn {
  color: #1cb99a;
  text-decoration: none;
}

.custom-date-picker .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 4px;
  line-height: 20px;
  border: 1px solid transparent;
}

.custom-date-picker .ant-picker-cell-selected .ant-picker-cell-inner,
.custom-date-picker .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #1cb99a;
}

.custom-date-picker .ant-picker-cell-today .ant-picker-cell-inner {
  border-color: #1cb99a;
}

.custom-date-picker .ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.custom-date-picker div.ant-picker-footer > a:hover {
  text-decoration: underline;
}

.custom-date-picker .ant-picker-dropdown-range {
  padding: 0;
}

/* custom-date-time-picker */
.custom-date-time-picker .ant-picker-footer .ant-picker-now-btn {
  color: #1cb99a;
  text-decoration: none;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-now-btn:hover {
  text-decoration: underline;
}

/* .custom-time-picker */
.custom-time-picker .ant-picker-panel {
  width: 100%;
}

.custom-time-picker.input-time-picker {
  padding: 4px 0;
}

.custom-time-picker .ant-picker-footer {
  display: none;
}

/* custom-date-time-picker */
/* .custom-time-picker */
.custom-date-time-picker .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
.custom-time-picker .ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  padding-left: 0 !important;
}

.custom-date-time-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner,
.custom-time-picker .ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: rgba(28, 185, 154, 0.06) !important;
}

.custom-date-time-picker .ant-picker-time-panel .ant-picker-time-panel-column,
.custom-time-picker .ant-picker-time-panel .ant-picker-time-panel-column {
  text-align: center;
  overflow: scroll;
  scrollbar-width: thin;
}

.custom-date-time-picker .ant-picker-time-panel-column::-webkit-scrollbar,
.custom-time-picker .ant-picker-time-panel-column::-webkit-scrollbar {
  width: 2px;
}

.custom-date-time-picker .ant-picker-time-panel-column::-webkit-scrollbar-thumb,
.custom-time-picker .ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 50px;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-ok button:not(:disabled),
.custom-time-picker .ant-picker-footer .ant-picker-ok button:not(:disabled) {
  background-color: #1cb99a;
  border-color: #1cb99a;
  border-radius: 4px;
}

.custom-date-time-picker .ant-picker-footer .ant-picker-ok button:disabled,
.custom-time-picker .ant-picker-footer .ant-picker-ok button:disabled {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.6);
  cursor: default;
}

/* Mui Lab */
/* custom-popup-date-picker */
.custom-popup-date-picker > .MuiPaper-root {
  border-radius: 4px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
}

.custom-popup-date-picker > .MuiPaper-root > div > div {
  width: auto;
}

.custom-popup-date-picker .MuiCalendarPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type > div {
  font-size: 14px;
  color: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root {
  border-radius: 4px;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-popup-date-picker .MuiCalendarPicker-root > div:first-of-type .MuiButtonBase-root.Mui-disabled svg {
  fill: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 170px;
  max-height: 240px;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .PrivatePickersSlideTransition-root {
  min-height: 210px;
  max-height: 250px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root > div {
  position: relative;
}

/* year */
.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton {
  font-size: 14px;
  width: auto;
  height: 26px;
  border-radius: 4px;
}

.custom-popup-date-picker .MuiYearPicker-root .PrivatePickersYear-yearButton.Mui-selected {
  background-color: #1cb99a;
}

/* month */
.custom-popup-date-picker .MuiMonthPicker-root {
  width: 280px;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root {
  height: 26px;
  margin: 8px 0;
  background-color: #fff;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  color: #29302e !important;
  position: relative;
  z-index: 1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected {
  color: #fff !important;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  right: 8px;
  border-radius: 4px;
  z-index: -1;
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root:hover::before {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-popup-date-picker .MuiMonthPicker-root .PrivatePickersMonth-root.Mui-selected::before {
  background-color: #1cb99a;
}

/* range date picker */
.custom-range-date-picker-wrap > .MuiPaper-root {
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.14);
}

.custom-range-date-picker-wrap > .MuiPaper-root ul {
  padding: 0;
}

.custom-range-date-picker-wrap > .MuiPaper-root ul > div {
  min-width: 280px;
  overflow-x: auto;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type * {
  font-size: 14px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:first-of-type svg {
  font-size: 18px;
  fill: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:first-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayPreview,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-root:last-of-type,
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.custom-range-date-picker-wrap .custom-popup-date-picker .MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgba(28, 185, 154, 0.1);
}

/* date */
.custom-popup-date-picker .PrivatePickersFadeTransitionGroup-root .MuiTypography-caption,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
}

.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiTypography-caption {
  width: 31px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today {
  background-color: #fff;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-disabled {
  color: #9e9e9e;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root {
  width: 27px;
  height: 27px;
  font-size: 14px;
  margin: 0 4px;
  color: #29302e;
  border-radius: 4px;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root:hover,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root:hover {
  background-color: rgba(0, 0, 0, 0.04);
  border: none;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today:hover {
  border: 1px solid #1cb99a;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.MuiPickersDay-today,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.MuiPickersDay-today {
  border-color: #1cb99a;
}

.custom-popup-date-picker .PrivatePickersSlideTransition-root .MuiPickersDay-root.Mui-selected,
.custom-range-date-picker-wrap .custom-popup-date-picker > div > div > div:nth-of-type(2) .MuiPickersDay-root.Mui-selected {
  color: #fff;
  background-color: #1cb99a;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #f5f5f5 !important;
}

/*
 * CKEditor 5 (v40.1.0) content styles.
 * Generated on Fri, 24 Nov 2023 10:36:46 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-selector-caption-background: hsl(0, 0%, 97%);
    --ck-color-selector-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
    table-layout: fixed;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
    overflow: hidden;
}
/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
    overflow-wrap: break-word;
    position: relative;
}
/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: hsl(0, 0%, 20%);
    color: var(--ck-color-selector-caption-text);
    background-color: hsl(0, 0%, 97%);
    background-color: var(--ck-color-selector-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
}
/* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    user-select: none;
}
/* @ckeditor/ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    position: relative;
    margin-bottom: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: 16px;
    width: var(--ck-todo-list-checkmark-size);
    height: 16px;
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content[dir=rtl] .todo-list .todo-list__label > input {
left: 0;
margin-right: 0;
right: -25px;
margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( 16px / 3 );
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( 16px / 5.3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( 16px / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( 16px / 2.6 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( 16px / 8 ) calc( 16px / 8 ) 0;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
    position: absolute;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input,
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
    cursor: pointer;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > input:hover::before, .ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input:hover::before {
    box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: 16px;
    width: var(--ck-todo-list-checkmark-size);
    height: 16px;
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content[dir=rtl] .todo-list .todo-list__label > span[contenteditable=false] > input {
    left: 0;
    margin-right: 0;
    right: -25px;
    margin-left: -15px;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow;
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc( 16px / 3 );
    left: calc( var(--ck-todo-list-checkmark-size) / 3 );
    top: calc( 16px / 5.3 );
    top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    width: calc( 16px / 5.3 );
    width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
    height: calc( 16px / 2.6 );
    height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc( 16px / 8 ) calc( 16px / 8 ) 0;
    border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label > span[contenteditable=false] > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}
/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
    position: absolute;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
    height: auto;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
    display: block;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}
/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}
/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0, 0%, 20%);
    color: var(--ck-color-image-caption-text);
    background-color: hsl(0, 0%, 97%);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: hsl(60, 97%, 73%);
    background-color: var(--ck-highlight-marker-yellow);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: hsl(120, 93%, 68%);
    background-color: var(--ck-highlight-marker-green);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: hsl(345, 96%, 73%);
    background-color: var(--ck-highlight-marker-pink);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: hsl(201, 97%, 72%);
    background-color: var(--ck-highlight-marker-blue);
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: hsl(0, 85%, 49%);
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}
/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: hsl(112, 100%, 27%);
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
    list-style-type: decimal;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
    list-style-type: lower-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
    list-style-type: lower-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
    list-style-type: disc;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
    list-style-type: circle;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
    list-style-type: square;
}
/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
    list-style-type: square;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - 1.5em);
    max-width: calc(100% - var(--ck-image-style-spacing));
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: 1.5em;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: 1.5em;
    margin-right: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: 1.5em;
    margin-left: var(--ck-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p + .image-style-align-left,
.ck-content p + .image-style-align-right,
.ck-content p + .image-style-side {
    margin-top: 0;
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: calc(1.5em / 2);
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: calc(1.5em / 2);
    margin-bottom: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: calc(1.5em / 2);
    margin-right: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: calc(1.5em / 2);
    margin-left: var(--ck-inline-image-style-spacing);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}
/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}
/* @ckeditor/ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: hsla(341, 100%, 30%, 0.1);
    background: var(--ck-color-mention-background);
    color: hsl(341, 100%, 30%);
    color: var(--ck-color-mention-text);
}
/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
}
/* @ckeditor/ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
}
@media print {
    /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
    }
    /* @ckeditor/ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break::after {
        display: none;
    }
}

body {
  overflow: auto;
  height: 100vh !important;
  overflow: hidden;
}

#root {
  height: 100vh !important;
  display: flex;
  flex-direction: column !important;
  /* overflow: hidden; */
}

.root {
  height: 100%;
  display: flex;
  overflow: auto;
  flex-direction: column;
  background-color: #f9fafc !important;
  font-size: 16px;
}


.ml {
  margin-left: 65px;
}

.container-menu {
  color: black;
  background-color: #f2f2f296 !important;
  z-index: 1500 !important;
}

.div-component {
  flex: 1 1;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}
.ck.ck-content:not(.ck-comment__input *) {
  height: 300px;
  width: 100% !important;
  overflow-y: auto;
}

/* .h-vh-100 {
  height: 100%;
} */

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.d-block {
  display: block;
}

.p-0 {
  padding: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.wp-150 {
  width: 150px !important;
}

.form-check-input:checked {
  background-color: #1cb99a !important;
  border-color: #1cb99a !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #1cb99a !important;
}

.Mui-checked {
  color: #1cb99a !important;
}

*::selection {
  color: unset;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1cb99a !important;
  border-color: #1cb99a !important;
}

.text-second {
  color: #1cb99a !important;
}
.sigCanvas {
  width: 100%;
  height: 232;
}

div[class$='jss'],
div[class$='jss']:hover {
  color: unset !important;
  background-color: unset !important;
}
