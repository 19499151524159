.btn-delete{
    background-color: #ff0000;
    color: #ffffff;
    cursor: 'pointer';
}
a.link-nav{
    color: #000000;
    text-decoration: unset ;
}
a.link-nav{
    color: #000000;
    text-decoration: none ;
}
/* a.link-nav:hover{
    color: #1cb99a;
    text-decoration: underline ;
}
.MuiFormLabel-root.Mui-focused{
    color: #1cb99a !important;
} */
.MuiFormControl-root{
    min-width: 200px ;
}
 